import React, { useState } from "react";
import RebateReportingAccordian from "./RebateReportingAccordian";

const MultiAccordian = ({
    Data,
    fromAddress,
    closeAccordian = true,
    handleAccordianEachAddress,
    handleAccordianSubdivisionAddresses,
    activeAddresses,
    subdivisions,
    cleanUpAction,
    refetch,
    uniqueHouses,
    search,
    productSearch
}) => {
    const [clicked, setClicked] = useState([]);
    const [accordianHouseClick, setAccordianHouseClick] = useState({});


    const toggle = (index) => {
        if (clicked.includes(index)) {
            //if clicked question is already active, then close it
            setClicked(clicked.filter((item) => item !== index));
        } else {
            setClicked([...clicked, index]);
        }
    };

    const houseClick = (index, data) => {
        let houseId = data.node.id;
        let houseArray = Data?.houses?.edges.filter(
                (item) => item?.node?.model?.id === houseId
            );
        
        setAccordianHouseClick({
            ...accordianHouseClick,
            [index]:
                houseArray &&
                houseArray.length > 0 &&
                houseArray[0]?.node?.pivots,
        });
    };

    const component = (node, subdivisionName, subdivisionId) => {
        return (
            <RebateReportingAccordian
                Data={node}
                fromAddress
                rebateReport
                onClick={(index, data) => {
                    houseClick(index, data);
                }}
                uniqueHouses={uniqueHouses}
                subdivisionName={subdivisionName}
                subdivisionId={subdivisionId}
                cleanUpAction={cleanUpAction}
                reportId={
                    Data?.id
                }
                refetch={refetch}
                DataArray={Data}
                products={accordianHouseClick}
                handleAccordianEachAddress={handleAccordianEachAddress}
                activeAddresses={activeAddresses}
            />
        );
    };

    return (
        <div className="flex flex-col w-full items-start justify-start bg-white">
            <div
                style={{marginBottom:"94px"}}
                className={`overflow-auto w-full scrollbar-thin scrollbar-thumb-lightPrimary scrollbar-track-gray-400`}
            >
                {subdivisions &&
                    subdivisions.activeSubdivisions &&
                    subdivisions.activeSubdivisions.edges &&
                    subdivisions.activeSubdivisions.edges.map((item, index) => {
                        let checked =
                            item.node.houses.edges.length > 0 &&
                            item.node.houses.edges
                                .map((item) => parseInt(item.node.id))
                                .every((val) =>
                                    activeAddresses
                                        ?.map((item) => parseInt(item.id))
                                        .includes(val)
                                );
                        let uniquePerHouse;
                        if(uniqueHouses?.length > 0) {
                            uniquePerHouse = item?.node?.houses?.edges?.map((house) => uniqueHouses?.includes(house.node.id))?.filter((item) => item === true);
                        }
                       
                        return (
                            <div className={`border-b`}>
                                <div
                                    className={`flex group py-1 justify-between items-center w-full text-center cursor-pointer transition-all  border-l-4 hover:border-l-6 ${
                                        clicked.includes(index) &&
                                        closeAccordian
                                            ? "bg-gray-300 border-l-6 border-gold"
                                            : "bg-white border-primary"
                                    }`}
                                    onClick={() => {
                                        toggle(index);
                                    }}
                                    key={index}
                                >
                                    <div className="flex w-full items-center px-2">
                                        <div className="flex items-center h-5">
                                            <input
                                                id="checkSubdivision"
                                                name="checkSubdivision"
                                                type="checkbox"
                                                checked={checked}
                                                onClick={(e) => e.stopPropagation()}
                                                onChange={() => {
                                                    handleAccordianSubdivisionAddresses(
                                                        item.node,
                                                        checked
                                                    );
                                                }}
                                                className="focus:ring-secondary h-4 w-4 text-secondary border-gray-300 rounded"
                                            />
                                        </div>
                                        <div className="py-2 px-2 w-full flex justify-between text-sm text-darkgray75 font-semibold">
                                            <p className="">
                                                {item?.node?.name}
                                            </p>
                                            <div
                                                className={`grid grid-cols-2 `}
                                            >
                                                <span
                                                    className={`place-self-start text-secondary font-normal ${
                                                        item?.node?.houses
                                                            ?.edges?.length < 2
                                                            ? "pr-3"
                                                            : ""
                                                    }`}
                                                >
                                                    {" "}
                                                    <p>
                                                        {" "}
                                                        {(item?.node?.houses
                                                            ?.edges?.length > 1 || uniquePerHouse?.length > 1)
                                                            ? `( ${uniquePerHouse?.length && productSearch? `Found in ${uniquePerHouse?.length} of` : ""} ${item?.node?.houses?.edges?.length} Properties)`
                                                            : `( ${uniquePerHouse?.length && productSearch? `Found in ${uniqueHouses?.length} of` : ""} ${item?.node?.houses?.edges?.length} Property)`}{" "}
                                                    </p>
                                                </span>
                                                <span className=" place-self-end">
                                                    {clicked.includes(index) &&
                                                    closeAccordian ? (
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            className="h-6 w-6 text-secondary"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            stroke="currentColor"
                                                        >
                                                            <path
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                strokeWidth="2"
                                                                d="M5 15l7-7 7 7"
                                                            />
                                                        </svg>
                                                    ) : null}
                                                    {!clicked.includes(
                                                        index
                                                    ) ? (
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            className="opacity-0 transition-opacity duration-150 h-6 w-6 group-hover:opacity-100 text-secondary"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            stroke="currentColor"
                                                        >
                                                            <path
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                strokeWidth="2"
                                                                d="M19 9l-7 7-7-7"
                                                            />
                                                        </svg>
                                                    ) : null}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {clicked.includes(index) && closeAccordian ? (
                                    <div className="bg-red w-full  flex flex-col justify-around items-center transition-all duration-1000 ">
                                        {component(
                                            search ? item?.node?.houses?.edges?.filter((house) => uniqueHouses?.includes(house.node.id)) : item?.node?.houses?.edges ,
                                            item?.node?.name,
                                            item?.node?.id
                                        )}
                                    </div>
                                ) : null}
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};

export default MultiAccordian;
