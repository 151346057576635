import { useLazyQuery } from "@apollo/client";
import { ArrowCircleRightIcon } from "@heroicons/react/outline";
import React, { useEffect, useRef, useCallback, useState } from "react";
import { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { AuthContext } from "../../../contexts/auth";
import {
    CLAIMS_PER_STATUS,
    CLAIM_CHART_REPORT,
    FETCH_ADMIN_REBATE_COUNT,
    FETCH_ALERTS,
    GET_CONVERSION_DATA,
    GET_CONVERSION_REVENUE,
} from "../../../lib/dashboard";
import {
    FETCH_BUILDER_AD_POST,
    FETCH_DEADLINE,
    RECENTLY_ADOPTED_PROGRAMS,
} from "../../../lib/wordpress";
import { getFormattedDate, toDateAdd } from "../../../util/generic";
import { wordPressClient } from "../../../util/wordpress";
import Testimonial from "../../Carousal/Testimonial";
import Loader from "../../Loader/Loader";
import { formatterForCurrency } from "../../../util/generic"
import { FULL_WHO_AM_I } from "../../../lib/auth";


const AdminDashboard = ({ type }) => {
    const first = 20;
    const subcontractorObserver = useRef();
    const [currentYearQuarter, setCurrentYearQuarter] = useState();
    const history = useHistory();

    const { userData,impersonator,setUserData,organizationNode,setOrganizationNode } = useContext(AuthContext);

    useEffect(() => {
        whoAmI();
        getRebateCount();
        getAdPosts();
        getClaimStatus();
        getDeadline();
        getAdoptedPrograms();
        getAnalyticsData();
        setCurrentYearandQuarter();
        getCurrentYearConversionRevenue();
        getPreviousYearConversionRevenue();
        getConversionData();
        // eslint-disable-next-line
    }, []);

    const [whoAmI, { data: user,loading:whoAmILoading }] = useLazyQuery(FULL_WHO_AM_I, {
        fetchPolicy: "no-cache",
        onCompleted: () => {
            if (!user?.whoAmI?.user) {
                setUserData(null);
            } else {
                let newNode = user?.whoAmI?.user;
                setOrganizationNode({...organizationNode,...newNode});
                setUserData({...userData,...newNode})
            }
        },
    });

    useEffect(() => {
        getAlerts();
        // eslint-disable-next-line
    }, [impersonator])

    // eslint-disable-next-line
    const lastSubcontractorElement = useCallback((node) => {
        if (subcontractorObserver.current)
            subcontractorObserver.current.disconnect();
        subcontractorObserver.current = new IntersectionObserver((entries) => {
            if (
                entries[0].isIntersecting &&
                alerts.userNotifications.pageInfo.hasNextPage
            ) {
                fetchMore({
                    variables: {
                        first,
                        after: alerts.userNotifications.pageInfo.endCursor,
                    },
                });
            }
        });
        if (node) subcontractorObserver.current.observe(node);
    });

    const [
        getAlerts,
        { data: alerts, fetchMore, loading: alertLoading },
    ] = useLazyQuery(FETCH_ALERTS, {
        variables: {
            first: first,
        },
        fetchPolicy:"network-only",
    });

    const [
        getAdoptedPrograms,
        { data: adoptedPrograms, loading: adoptedProgramLoading },
    ] = useLazyQuery(RECENTLY_ADOPTED_PROGRAMS);

    const [
        getRebateCount,
        { data: rebateCount, loading: rebateCountLoading },
    ] = useLazyQuery(FETCH_ADMIN_REBATE_COUNT, {});

    const [getAdPosts, { data: adData, loading:adLoading }] = useLazyQuery(FETCH_BUILDER_AD_POST, {
        notifyOnNetworkStatusChange: false,
        client: wordPressClient,
    });

    const [getDeadline, { data: deadline }] = useLazyQuery(FETCH_DEADLINE, {
        notifyOnNetworkStatusChange: false,
        client: wordPressClient,
    });

    const [getClaimStatus, { data: claimData }] = useLazyQuery(
        CLAIMS_PER_STATUS,
        {
            notifyOnNetworkStatusChange: false,
        }
    );

    const [
        getCurrentYearConversionRevenue,
        { data: currentYearConversion },
    ] = useLazyQuery(GET_CONVERSION_REVENUE, {
        variables: {
            year: currentYearQuarter?.year,
        },
        notifyOnNetworkStatusChange: false,
        fetchPolicy: "network-only",
    });

    const [
        getPreviousYearConversionRevenue,
        { data: previousYearConversion },
    ] = useLazyQuery(GET_CONVERSION_REVENUE, {
        variables: {
            year: currentYearQuarter?.year - 1,
        },
        notifyOnNetworkStatusChange: false,
        fetchPolicy: "network-only",
    });

    const displayDeadline = (d) => {
        var date = d?.split("/");
        var f = new Date(date?.[2], date?.[1] - 1, date?.[0]).getTime();

        let now = new Date().getTime();
        const compare = 1814400;

        if ( f - compare > now ) {
            return true;
        } else {
            return false;
        }
    };

    const setCurrentYearandQuarter = () => {
        var today = new Date();
        var quarter = Math.floor((today.getMonth() + 3) / 3);
        var year = today.getFullYear();
        setCurrentYearQuarter({
            year: year,
            quarter: quarter,
        });
    };

    const [
        getAnalyticsData,
        { data: analyticsData, loading: chartLoading },
    ] = useLazyQuery(CLAIM_CHART_REPORT, {
        variables: {
            year: currentYearQuarter?.year,
            quarter: currentYearQuarter?.quarter,
        },
    });

    const [
        getConversionData,
        { data: conversionData, loading: conversionLoading },
    ] = useLazyQuery(GET_CONVERSION_DATA);



    const alertHandler = (type, entity) => {

        if (type === "Programs" && entity?.id) {
            history.push({
                pathname: "/programs",
                state: { id: entity?.id, from: "notification" },
            });
        } else if  (type === "Organizations" && entity?.id) {
            history.push({
                pathname: "/profiles/builders",
                state: { id: entity?.id, from: "notification" },
            });
        }
        
        // else if  (type === "Bundles" && entity?.id) {
        //     history.push({
        //         pathname: "/reporting/bundles",
        //         state: { id: entity?.id,org_id:entity?.organization?.id, from: "notification" },
        //     });
        // } else if  (type === "SubDivision" && entity?.id) {
        //     history.push({
        //         pathname: "/reporting/addresses",
        //         state: { id: entity?.id, org_id:entity?.organization?.id, from: "notification" },
        //     });
        // } else if  (type === "Disputes" && entity?.id) {
        //     history.push({
        //         pathname: "/claims/createclaim",
        //         state: { id: entity?.id, org_id:entity?.claim?.id, from: "notification" },
        //     });
        // }
    };


    const makeGraphs = () => {
        let report = analyticsData?.claimChartReport;

        let yearCloseMax = Math.max(
            report?.yearCloseClaims?.factoryTotal +
                report?.yearCloseClaims?.volumeTotal,
            report?.yearCloseClaims?.previousResults?.factoryTotal +
                report?.yearCloseClaims?.previousResults?.volumeTotal
        );

        let periodCloseMax = Math.max(
            report?.periodCloseClaims?.factoryTotal +
                report?.periodCloseClaims?.volumeTotal,
            report?.periodCloseClaims?.previousResults?.factoryTotal +
                report?.periodCloseClaims?.previousResults?.volumeTotal
        );

        let conversionMax = Math.max(
            currentYearConversion?.conversionRevenue?.yearTotal?.total,
            previousYearConversion?.conversionRevenue?.yearTotal?.total
        );

        let totalMax = Math.max(yearCloseMax, periodCloseMax, conversionMax);

        let maxHeight = 175;
        const findHeight = (value, relative) => {
            return (value * maxHeight) / relative;
        };

        let currentPeriodHeight = findHeight(
            report?.periodCloseClaims?.factoryTotal +
                report?.periodCloseClaims?.volumeTotal,
            totalMax
        );
        let pastPeriodHeight = findHeight(
            report?.periodCloseClaims?.previousResults?.factoryTotal +
                report?.periodCloseClaims?.previousResults?.volumeTotal,
            totalMax
        );

        let currentYearHeight = findHeight(
            report?.yearCloseClaims?.factoryTotal +
                report?.yearCloseClaims?.volumeTotal,
            totalMax
        );

        let pastYearHeight = findHeight(
            report?.yearCloseClaims?.previousResults?.factoryTotal +
                report?.yearCloseClaims?.previousResults?.volumeTotal,
            totalMax
        );

        let currentConversionHeight = findHeight(
            currentYearConversion?.conversionRevenue?.yearTotal?.total,
            totalMax
        );

        let pastConversionHeight = findHeight(
            previousYearConversion?.conversionRevenue?.yearTotal?.total,
            totalMax
        );

        return (
            <div className="flex w-full  4xl:space-x-5">
                {
                    chartLoading?
                    <Loader className={"w-full"}/>
                    :
                <div className="flex py-1  w-full px-2 space-x-2  4xl:space-x-10">
                    <div className="flex flex-col w-full justify-end items-center 4xl:pl-5">
                        <div className="flex   items-end w-full">
                            <div
                                className="w-full text-center flex flex-col   font-medium "
                                style={{ maxWidth: "100px" }}
                            >
                                <div className=" ">
                                    {formatterForCurrency.format(
                                        report?.periodCloseClaims?.previousResults?.factoryTotal +
                                            report?.periodCloseClaims?.previousResults?.volumeTotal
                                    )}
                                    <div
                                        className="bg-darkgray75 w-full duration-250 border-b-2 border-black transition-height"
                                        style={{
                                            height: `${isNaN(pastPeriodHeight) ? "0" : pastPeriodHeight}px`,
                                        }}
                                    >
                                        &nbsp;
                                    </div>
                                    <p className="pt-2">Q{currentYearQuarter?.quarter - 1}</p>
                                </div>
                            </div>
                            <div
                                className="w-full text-center flex flex-col   font-medium"
                                style={{ maxWidth: "100px" }}
                            >
                                <div className=" ">
                                    {formatterForCurrency.format(
                                        report?.periodCloseClaims?.factoryTotal + report?.periodCloseClaims?.volumeTotal
                                    )}
                                    <div
                                        className="bg-secondary w-full duration-250 border-b-2 border-black transition-height"
                                        style={{
                                            height: `${isNaN(currentPeriodHeight) ? "0" : currentPeriodHeight}px`,
                                        }}
                                    >
                                        &nbsp;
                                    </div>
                                    <p className="pt-2">Q{currentYearQuarter?.quarter}</p>
                                </div>
                            </div>
                        </div>
                        <p className=" text-secondary font-title">Reporting Period</p>
                    </div>
                    <div className="flex flex-col w-full justify-end items-center  4xl:pl-10">
                        <div className="flex     items-end w-full">
                            <div
                                className="w-full text-center flex flex-col   font-medium"
                                style={{ maxWidth: "100px" }}
                            >
                                <div className=" ">
                                    {formatterForCurrency.format(
                                        report?.yearCloseClaims?.previousResults?.factoryTotal +
                                            report?.yearCloseClaims?.previousResults?.volumeTotal
                                    )}
                                    <div
                                        className="bg-darkgray75 w-full duration-250 border-b-2 border-black transition-height"
                                        style={{
                                            height: `${isNaN(pastYearHeight) ? "0" : pastYearHeight}px`,
                                        }}
                                    >
                                        &nbsp;
                                    </div>
                                    <p className="pt-2">{currentYearQuarter?.year - 1}</p>
                                </div>
                            </div>
                            <div
                                className="w-full text-center flex flex-col   font-medium"
                                style={{ maxWidth: "100px" }}
                            >
                                <div className=" ">
                                    {formatterForCurrency.format(
                                        report?.yearCloseClaims?.factoryTotal + report?.yearCloseClaims?.volumeTotal
                                    )}
                                    <div
                                        className="bg-secondary w-full duration-250 border-b-2 border-black transition-height"
                                        style={{
                                            height: `${isNaN(currentYearHeight) ? "0" : currentYearHeight}px`,
                                        }}
                                    >
                                        &nbsp;
                                    </div>
                                    <p className="pt-2">{currentYearQuarter?.year}</p>
                                </div>
                            </div>
                        </div>
                        <p className=" text-secondary font-title">Total</p>
                    </div>
                    <div className="flex flex-col w-full justify-end items-center  4xl:pl-10">
                        <div className="flex     items-end w-full">
                            <div
                                className="w-full text-center flex flex-col   font-medium"
                                style={{ maxWidth: "100px" }}
                            >
                                <div className=" ">
                                    {formatterForCurrency.format(
                                        previousYearConversion?.conversionRevenue?.yearTotal?.total
                                    )}
                                    <div
                                        className="bg-darkgray75 w-full duration-250 border-b-2 border-black transition-height"
                                        style={{
                                            height: `${isNaN(pastConversionHeight) ? "0" : pastConversionHeight}px`,
                                        }}
                                    >
                                        &nbsp;
                                    </div>
                                    <p className="pt-2">{currentYearQuarter?.year - 1}</p>
                                </div>
                            </div>
                            <div
                                className="w-full text-center flex flex-col   font-medium"
                                style={{ maxWidth: "100px" }}
                            >
                                <div className=" ">
                                    {formatterForCurrency.format(
                                        currentYearConversion?.conversionRevenue?.yearTotal?.total
                                    )}
                                    <div
                                        className="bg-secondary w-full duration-250 border-b-2 border-black transition-height"
                                        style={{
                                            height: `${
                                                isNaN(currentConversionHeight) ? "0" : currentConversionHeight
                                            }px`,
                                        }}
                                    >
                                        &nbsp;
                                    </div>
                                    <p className="pt-2">{currentYearQuarter?.year}</p>
                                </div>
                            </div>
                        </div>
                        <p className=" text-secondary font-title">Conversion</p>
                    </div>
                </div>
    }
                <div
                    className="border-l w-full flex flex-col"
                    style={{ maxWidth: "350px", maxHeight: "350px", overflowY: "auto" }}
                >
                   {whoAmILoading ? (
                        <Loader />
                    ) :
                    <>
                    <div className="py-3  font-title text-secondary px-4 font-semibold border-b text-lg">
                        <p className=" text-left"> Project Revenue Total </p>
                        <p className="text-right">
                            {" "}
                            {formatterForCurrency.format(userData?.projectedRevenue?.projectedTotal)}  
                        </p>
                    </div>
                   {userData?.projectedRevenue?.programs?.map((eachData, index) => {
                            return (
                                <div
                                    className={`  border-b transition-all  border-l-4 border-l-primary  hover:border-l-6`}
                                    onClick={() =>
                                        history.push({
                                            pathname: "/programs",
                                            state: {
                                                id: eachData?.id,
                                                from: "notification",
                                            },
                                        })
                                    }
                                >
                                    <div className="relative flex items-start py-3 justify-between px-4  ">
                                        <div className="flex flex-col">
                                            <div className="text-sm  font-semibold  ">
                                                <div className="  focus:outline-none">
                                                    <span className="absolute inset-0" aria-hidden="true"></span>
                                                    {eachData.name}
                                                </div>
                                            </div>
                                            <div className="flex items-center ">
                                                <p className="text-sm text-gray-500 capitalize ">
                                                    {eachData?.type?.toLowerCase()}
                                                </p>

                                                <p className="text-sm text-gray-500 capitalize ml-1">
                                                    {eachData?.company?.name ? "  - " + eachData?.company?.name : ""}
                                                </p>
                                            </div>
                                        </div>
                                        <p className="text-sm font-semibold">
                                            {" "}
                                            {eachData?.contributedTotal
                                                ? formatterForCurrency.format(eachData?.contributedTotal)
                                                : "0"}{" "}
                                        </p>
                                    </div>
                                </div>
                            );
                        })}
                    </> }
                </div>
            </div>
        );
    };

    return (
        <>
            {displayDeadline(deadline?.acfOptions?.deadlineAlert?.date) ? (
                <p className="py-3 font-title text-brickRed px-4 font-semibold border-b text-lg bg-white rounded-lg">
                    {deadline?.acfOptions?.deadlineAlert?.message} -{" "}
                    {deadline?.acfOptions?.deadlineAlert?.date}
                </p>
            ) : null}
            <div className=" grid grid-cols-3    w-full gap-5">
                <div className="w-full border bg-white rounded-lg col-span-2 relative  ">
                    <div className="flex  h-full  ">
                        <div className="flex-1  flex  w-full">
                            <div className="h-full w-full ">
                                <div className="w-full h-full flex justify-center">
                                    {makeGraphs()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="h-full w-full bg-white border rounded-lg ">
                    <p className="py-3 font-title text-secondary px-4 font-semibold border-b text-lg ">
                        Alert & Notifications
                    </p>
                    <ul
                        className={`flex-0 w-full h-full  overflow-auto border-l  border-white scrollbar-thin scrollbar-thumb-lightPrimary scrollbar-track-gray-400 ${
                            displayDeadline(
                                deadline?.acfOptions?.deadlineAlert?.date
                            )
                                ? "max-h-60"
                                : "max-h-60"
                        }`}
                        style={{minHeight:"300px",maxHeight:"300px"}}
                      
                    >
                        {alertLoading ? (
                            <Loader />
                        ) : (
                            alerts &&
                            alerts.userNotifications &&
                            alerts.userNotifications.edges.length !== 0 &&
                            alerts.userNotifications.edges.map(
                                (eachData, index) => {
                                    let verify = eachData?.node?.related_entity?.id && (eachData?.node
                                        ?.related_entity
                                        ?.__typename !== "Disputes" && eachData?.node
                                        ?.related_entity
                                        ?.__typename !== "Bundles" && eachData?.node
                                        ?.related_entity
                                        ?.__typename !== "SubDivision")
                                    if (
                                        index ===
                                        alerts.userNotifications.edges.length -
                                            1
                                    ) {
                                        return (
                                            <li
                                                className={`  border-b transition-all  border-l-4    hover:border-l-6  border-l-primary`}
                                                ref={lastSubcontractorElement}
                                            >
                                                <div
                                                    className={`${ verify ?  "cursor-pointer": ""}`}
                                                    onClick={() =>
                                                        alertHandler(
                                                            eachData?.node
                                                                ?.related_entity
                                                                ?.__typename,
                                                            eachData?.node
                                                                ?.related_entity
                                                        )
                                                    }
                                                >
                                                    <div className="text-sm py-3 px-4 font-semibold text-darkgray75 flex justify-between items-center">
                                                        <div className="  focus:outline-none">
                                                            {
                                                                eachData.node
                                                                    .message
                                                            }{" - "}
                                                            {eachData?.node?.related_entity?.name ? eachData?.node?.related_entity?.name : null}
                                                        </div>
                                                       { verify ? <ArrowCircleRightIcon className="h-5 w-5 text-secondary" /> : null}
                                                    </div>
                                                </div>
                                            </li>
                                        );
                                    }
                                    return (
                                        <li
                                            className={`  border-b transition-all  border-l-4    hover:border-l-6  border-l-primary `}
                                        >
                                            <div
                                                 className={`${verify ? "cursor-pointer": ""}`}
                                                onClick={() =>
                                                    alertHandler(
                                                        eachData?.node
                                                            ?.related_entity
                                                            ?.__typename,
                                                        eachData?.node
                                                            ?.related_entity
                                                    )
                                                }
                                            >
                                                <div className="text-sm py-3 px-4 font-semibold text-darkgray75 flex justify-between items-center">
                                                        <div className="  focus:outline-none">
                                                            {
                                                                eachData.node
                                                                    .message
                                                            }{" - "}
                                                            {eachData?.node?.related_entity?.name ? eachData?.node?.related_entity?.name : null}

                                                        </div>
                                                        {verify? <ArrowCircleRightIcon className="h-5 w-5 text-secondary" /> : null }
                                                    </div>
                                            </div>
                                        </li>
                                    );
                                }
                            )
                        )}
                    </ul>
                </div>
            </div>

            <div className="grid grid-cols-3 w-full gap-5">
                <div
                    className="w-full border bg-white rounded-lg"
                    style={{ minHeight: "220px" }}
                >
                    <p className="py-3 font-title text-secondary px-4 font-semibold border-b text-lg">
                        Claims
                    </p>
                    <ul className="flex flex-wrap px-4 py-2 gap-x-5">
                        <li className="my-2 flex  rounded-md border">
                            <div
                                className={`flex-shrink-0 flex items-center justify-center w-16 text-brickRed text-xl font-medium rounded-l-md`}
                            >
                                {
                                    claimData?.totalInProgressClaims?.pageInfo
                                        ?.total?.toLocaleString()
                                }
                            </div>
                            <div className="flex-1 flex items-center justify-between bg-white border-l rounded-r-md truncate">
                                <div className="flex-1 px-4 py-2 text-lg">
                                    <Link
                                        to={{pathname:"/claims/createclaim"}}
                                        className="text-gray-900 font-title  hover:text-gray-600"
                                    >
                                        In Progress
                                    </Link>
                                </div>
                            </div>
                        </li>
                        <li className="my-2 flex  rounded-md border ">
                            <div
                                className={`flex-shrink-0 flex items-center justify-center w-16 text-secondary text-xl font-medium rounded-l-md`}
                            >
                                {
                                    claimData?.readyForSubmittalClaims?.pageInfo
                                        ?.total?.toLocaleString()
                                }
                            </div>
                            <div className="flex-1 flex items-center justify-between bg-white border-l rounded-r-md truncate">
                                <div className="flex-1 px-4 py-2 text-lg">
                                    <Link
                                         to={{pathname:"/claims/factoryworkflow"}}
                                        className="text-gray-900 font-title  hover:text-gray-600"
                                    >
                                        Ready for Submittal
                                    </Link>
                                </div>
                            </div>
                        </li>
                        <li className="my-2 flex  rounded-md border">
                            <div
                                className={`flex-shrink-0 flex items-center justify-center w-16 text-secondary text-xl font-medium rounded-l-md`}
                            >
                                {
                                    claimData?.totalSubmittedClaims?.pageInfo
                                        ?.total?.toLocaleString()
                                }
                            </div>
                            <div className="flex-1 flex items-center justify-between bg-white border-l rounded-r-md truncate">
                                <div className="flex-1 px-4 py-2 text-lg">
                                    <Link
                                          to={{pathname:"/claims/factoryworkflow"}}
                                        className="text-gray-900 font-title  hover:text-gray-600"
                                    >
                                        Submitted
                                    </Link>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="bg-white border rounded-lg w-full">
                    <p className="py-3 font-title text-secondary px-4 font-semibold border-b text-lg">
                        Rebate Reporting
                    </p>

                    {rebateCountLoading ? (
                        <Loader />
                    ) : (
                        <ul className="flex flex-col px-4 py-2">
                            <li className="my-2 flex  rounded-md border">
                                <div
                                    className={`flex-shrink-0 flex items-center justify-center w-16 text-brickRed text-xl font-medium rounded-l-md`}
                                >
                                    {
                                        rebateCount?.actionNeededRebate
                                            ?.rebateCount?.toLocaleString()
                                    }
                                </div>
                                <div className="flex-1 flex items-center justify-between bg-white border-l rounded-r-md truncate">
                                    <div className="flex-1 px-4 py-2 text-lg">
                                        <Link
                                             to={{pathname:"/reporting/prepare"}}
                                            className="text-gray-900 font-title  hover:text-gray-600"
                                        >
                                            Actions Required
                                        </Link>
                                    </div>
                                </div>
                            </li>
                            <li className="my-2 flex  rounded-md border">
                                <div
                                    className={`flex-shrink-0 flex items-center justify-center w-16 text-secondary text-xl font-medium rounded-l-md`}
                                >
                                    {
                                        rebateCount?.readiedRebatesCount
                                            ?.rebateCount?.toLocaleString()
                                    }
                                </div>
                                <div className="flex-1 flex items-center justify-between bg-white border-l rounded-r-md truncate">
                                    <div className="flex-1 px-4 py-2 text-lg">
                                        <Link
                                            to={{pathname:"/reporting/prepare", active: "1"}}
                                            className="text-gray-900 font-title  hover:text-gray-600"
                                        >
                                            Ready for Rebate
                                        </Link>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    )}
                </div>

                <div className=" bg-white border rounded-lg w-full ">
                    <p className="py-3 font-title text-secondary px-4 font-semibold border-b text-lg ">
                        Conversions
                    </p>
                    <div className="flex flex-col overflow-auto border-l  border-white scrollbar-thin scrollbar-thumb-lightPrimary scrollbar-track-gray-400 max-h-52">
                        <ul className={`flex-0 w-full`}>
                            {conversionLoading ? (
                                
                                <Loader />
                               
                            ) : (
                                conversionData?.conversionPaymentsPastDue
                                    ?.slice(0, 5)
                                    // eslint-disable-next-line
                                    ?.map((eachPackage) => {
                                        if (
                                            eachPackage?.__typename ===
                                                "ConversionFlatPayment" ||
                                            eachPackage?.__typename ===
                                                "ConversionFlatPercent"
                                        ) {
                                            return (
                                                <li
                                                    className={`  border-b transition-all  border-l-4  hover:border-l-6  text-darkgray75 border-l-brickRed`}
                                                    onClick={() => history.push({pathname:"/programs", state: { id: eachPackage?.program?.id, from: "notification",open:"conversions" },})}
                                                >
                                                    <div className="relative flex px-4  py-2 space-x-5">
                                                        <div className="flex flex-col text-sm w-full pr-2">
                                                            <p className="text-left  font-bold">
                                                                {" "}
                                                                {
                                                                    eachPackage?.name
                                                                }
                                                            </p>
                                                            <div className="flex items-center space-x-1">
                                                                <p className=" text-xs text-left">
                                                                    {" "}
                                                                    {
                                                                        eachPackage
                                                                            ?.program
                                                                            ?.name
                                                                    }
                                                                </p>
                                                                <p> - </p>
                                                                <p className=" italic text-xs capitalize text-left">
                                                                    {eachPackage?.program?.type.toLowerCase()}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-col text-sm w-full pr-2" style={{maxWidth:"100px"}}>
                                                            {eachPackage?.__typename ===
                                                            "ConversionFlatPayment" ? (
                                                                <p className="text-right  font-bold">
                                                                    {" "}
                                                                    {formatterForCurrency.format(eachPackage?.amount)}
                                                                </p>
                                                            ) : (
                                                                <p className="text-right  font-bold">
                                                                    {" "}
                                                                    {
                                                                        eachPackage?.amount
                                                                    }
                                                                </p>
                                                            )}

                                                            <p className=" italic text-xs capitalize text-right">
                                                                {" "}
                                                                {getFormattedDate(toDateAdd(
                                                                    eachPackage?.anticipated_payment_date
                                                                ))
                                                                }
                                                            </p>
                                                        </div>
                                                    </div>
                                                </li>
                                            );
                                        }
                                    })
                            )}
                        </ul>
                        <ul className={`flex-0 w-full  `}>
                            {
                                conversionData?.upcomingConversionPayments
                                    ?.slice(0, 5)
                                    // eslint-disable-next-line
                                    ?.map((eachPackage) => {
                                        if (
                                            eachPackage?.__typename ===
                                                "ConversionFlatPayment" ||
                                            eachPackage?.__typename ===
                                                "ConversionFlatPercent"
                                        ) {
                                            return (
                                                <li
                                                    className={`  border-b transition-all  border-l-4  hover:border-l-6  text-darkgray75 border-l-primary`}
                                                >
                                                    <div className="relative flex px-4  py-2 space-x-5">
                                                        <div className="flex flex-col text-sm w-full pr-2">
                                                            <p className="text-left  font-bold">
                                                                {" "}
                                                                {
                                                                    eachPackage?.name
                                                                }
                                                            </p>
                                                            <div className="flex items-center space-x-3">
                                                                <p className=" text-xs text-left">
                                                                    {" "}
                                                                    {
                                                                        eachPackage
                                                                            ?.program
                                                                            ?.name
                                                                    }
                                                                </p>
                                                                <p> - </p>
                                                                <p className=" italic text-xs capitalize text-left">
                                                                    {eachPackage?.program?.type.toLowerCase()}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-col text-sm w-full pr-2">
                                                            {eachPackage?.__typename ===
                                                            "ConversionFlatPayment" ? (
                                                                <p className="text-right  font-bold">
                                                                    {" "}
                                                                    {formatterForCurrency.format(eachPackage?.amount)}
                                                                </p>
                                                            ) : (
                                                                <p className="text-right  font-bold">
                                                                    {" "}
                                                                    {
                                                                        eachPackage?.amount
                                                                    }
                                                                </p>
                                                            )}
                                                            <p className=" italic text-xs capitalize text-right">
                                                                {" "}
                                                                {getFormattedDate(toDateAdd(
                                                                    eachPackage?.anticipated_payment_date
                                                                ))
                                                            }
                                                            </p>
                                                        </div>
                                                    </div>
                                                </li>
                                            );
                                        }
                                    })
                            }
                        </ul>
                    </div>
                </div>
            </div>

            <div className="   grid grid-cols-3   w-full gap-5">
                <div className="w-full col-span-2 border bg-white rounded-lg ">
                    <Testimonial slides={adData} loading={adLoading} />
                </div>

                <div className=" w-full bg-white border rounded-lg col-span-1">
                    <p className="py-3 font-title text-secondary px-4 font-semibold border-b text-lg ">
                        Program Adoption
                    </p>
                    <ul
                        className={`flex-0 w-full h-full  overflow-auto border-l  border-white scrollbar-thin scrollbar-thumb-lightPrimary scrollbar-track-gray-400 ${
                            displayDeadline(
                                deadline?.acfOptions?.deadlineAlert?.date
                            )
                                ? "max-h-48"
                                : "max-h-48"
                        }`}
                        style={{ minHeight: "11.25rem" }}
                    >
                        {adoptedProgramLoading ? (
                            <Loader />
                        ) : (
                            adoptedPrograms?.recentlyAdoptedPrograms
                                ?.slice(0, 5)
                                ?.map((eachPackage) => {
                                    return (
                                        <li
                                            className={`  border-b transition-all  border-l-4  hover:border-l-6  text-darkgray75 border-l-primary cursor-pointer`}
                                            onClick={() => history.push({pathname:"/profiles/builders", state: { id: eachPackage?.organization?.id, from: "notification",open:"programs" },})}
                                        >
                                            <div className="relative flex px-4  py-2 space-x-5">
                                                <div className="text-md w-full  font-semibold">
                                                    {
                                                        eachPackage
                                                            ?.organization?.name
                                                    }
                                                </div>
                                                <div className="flex flex-col text-sm w-full pr-2">
                                                    <p className="text-right  font-bold">
                                                        {" "}
                                                        {
                                                            eachPackage?.program
                                                                ?.name
                                                        }
                                                    </p>
                                                    <p className=" italic text-xs capitalize text-right">
                                                        {" "}
                                                        {eachPackage?.program?.type.toLowerCase()}
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                    );
                                })
                        )}
                    </ul>
                </div>
            </div>
        </>
    );
};

export default AdminDashboard;
