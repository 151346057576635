import React, { useState } from 'react'
import { Disclosure, Transition } from "@headlessui/react";
import { ClaimsTab } from './ClaimsTab';
import {
    ChevronDownIcon,
    ChevronUpIcon,
} from "@heroicons/react/solid";
import Loader from '../../../../Loader/Loader';
import DisputeDetails from './DisputeDetails';

export const ClaimDetails = () => {



    const [active, setActive] = useState("claimDetails");
    const [show, setShow] = useState(true);
    const [disputeClaimId, setDisputeClaimId] = useState();
    const [disputeClaimName,setDisputeClaimName] = useState("")


    const activeHandler = (item) => {
        if (item !== active) {
            setActive(item);
            setShow(true);
        } else {

            setShow(!show);
        }
    };

    const openDisputeTab = (id,name) => {
        setDisputeClaimId(id);
        setActive("disputes");
        setDisputeClaimName(name)
    }

    return (
        <div>
            <Disclosure as="div" className="">
                <Disclosure.Button
                    className={`bg-white w-full  focus:outline-none ${active === "claimDetails" && show
                            ? "rounded-lg rounded-b-none"
                            : "rounded-lg"
                        }`}
                >
                    <div
                        style={{ maxHeight: "68px" }}
                        className={`flex flex-col md:flex-row py-4 px-4 justify-between items-center ${active === "claimDetails" && show
                                ? "border-b-2 border-gray-400"
                                : ""
                            }`}
                        onClick={() => activeHandler("claimDetails")}
                    >
                        <div className=" font-title  text-center h2">
                            Claims Details{disputeClaimName && active === "disputes"? ": "+ disputeClaimName : ""}
                        </div>
                        {active === "claimDetails" && show ? (
                            <ChevronUpIcon className="h-10 w-10 text-secondary" />
                        ) : (
                            <ChevronDownIcon className="h-10 w-10 text-secondary" />
                        )}
                    </div>
                </Disclosure.Button>
                <Transition
                    show={active === "claimDetails" && show}
                    enter="transition duration-100 ease-out"
                    enterFrom="transform scale-95 opacity-0"
                    enterTo="transform scale-100 opacity-100"
                    leave="transition duration-150 ease-out"
                    leaveFrom="transform scale-100 opacity-100"
                    leaveTo="transform scale-95 opacity-0"
                >
                    <Disclosure.Panel static>
                        <div className="grid bg-white  rounded-lg rounded-t-none grid-cols-6 overflow-hidden min-h-smallMin">
                            {false ? (
                                <div className="col-span-6 flex items-center justify-center">
                                    <Loader />
                                </div>
                            ) : (
                                <div className="col-span-6 inset-0 flex flex-col">
                                    <ClaimsTab openDisputeTab={(id,name) => openDisputeTab(id,name)} />
                                </div>
                            )}
                        </div>
                    </Disclosure.Panel>
                </Transition>
            </Disclosure>
            {
                disputeClaimId && active === "disputes" ?

                    <Disclosure as="div" className="mt-5">
                        <Disclosure.Button
                            className={`bg-white w-full  focus:outline-none ${active === "disputes" && show
                                    ? "rounded-lg rounded-b-none"
                                    : "rounded-lg"
                                }`}
                        >
                            <div
                                style={{ maxHeight: "68px" }}
                                className={`flex flex-col md:flex-row py-4 px-4 justify-between items-center ${active === "disputes" && show
                                        ? "border-b-2 border-gray-400"
                                        : ""
                                    }`}
                                onClick={() => activeHandler("disputes")}
                            >
                                <div className=" font-title  text-center h2">
                                    Dispute Management
                                </div>
                                {active === "disputes" && show ? (
                                    <ChevronUpIcon className="h-10 w-10 text-secondary" />
                                ) : (
                                    <ChevronDownIcon className="h-10 w-10 text-secondary" />
                                )}
                            </div>
                        </Disclosure.Button>
                        <Transition
                            show={active === "disputes" && show}
                            enter="transition duration-100 ease-out"
                            enterFrom="transform scale-95 opacity-0"
                            enterTo="transform scale-100 opacity-100"
                            leave="transition duration-150 ease-out"
                            leaveFrom="transform scale-100 opacity-100"
                            leaveTo="transform scale-95 opacity-0"
                        >
                            <Disclosure.Panel static>
                                <div className="grid bg-white  rounded-lg rounded-t-none grid-cols-6 overflow-hidden min-h-smallMin">
                                    {false ? (
                                        <div className="col-span-6 flex items-center justify-center">
                                            <Loader />
                                        </div>
                                    ) : (
                                        <div className="col-span-6 inset-0 flex flex-col">
                                            <DisputeDetails claimId={disputeClaimId} confirmAction={() => setActive("claimDetails")} />
                                        </div>
                                    )}
                                </div>
                            </Disclosure.Panel>
                        </Transition>
                    </Disclosure> : null}
        </div>
    )
}

export default ClaimDetails
