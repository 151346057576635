import { useLazyQuery } from "@apollo/client";
import { ArrowCircleRightIcon } from "@heroicons/react/outline";
import React, { useEffect, useRef, useCallback, useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { AuthContext } from "../../../contexts/auth";
import {
    BUILDERS_WITHOUT_BUNDLES,
    CLAIM_CHART_REPORT,
    FETCH_ALERTS,
    GET_CONVERSION_REVENUE,
} from "../../../lib/dashboard";
import { FETCH_DEADLINE, FETCH_TM_AD_POST } from "../../../lib/wordpress";
import { wordPressClient } from "../../../util/wordpress";
import Testimonial from "../../Carousal/Testimonial";
import Loader from "../../Loader/Loader";
import { formatterForCurrency } from "../../../util/generic";
import { FULL_WHO_AM_I } from "../../../lib/auth";

const TMDashboard = ({ type }) => {
    const first = 20;
    const subcontractorObserver = useRef();
    const bundlesObserver = useRef();
    const [currentYearQuarter, setCurrentYearQuarter] = useState();
    const { organizationNode, impersonator, setUserData, setOrganizationNode, userData } = useContext(AuthContext);
    const history = useHistory();

    useEffect(() => {
        whoAmI();
        getAdPosts();
        getBuildersWithNoBundles();
        getDeadline();
        getAnalyticsData();
        setCurrentYearandQuarter();
        getCurrentYearConversionRevenue();
        getPreviousYearConversionRevenue();
        // eslint-disable-next-line
    }, []);

    const [whoAmI, { data: user, loading: whoAmILoading }] = useLazyQuery(FULL_WHO_AM_I, {
        fetchPolicy: "no-cache",
        onCompleted: () => {
            if (!user?.whoAmI?.user) {
                setUserData(null);
            } else {
                let newNode = user?.whoAmI?.user;
                setOrganizationNode({ ...organizationNode, ...newNode });
                setUserData({ ...userData, ...newNode });
            }
        },
    });

    useEffect(() => {
        getAlerts();
        // eslint-disable-next-line
    }, [impersonator]);

    // eslint-disable-next-line
    const lastSubcontractorElement = useCallback((node) => {
        if (subcontractorObserver.current) subcontractorObserver.current.disconnect();
        subcontractorObserver.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting && alerts.userNotifications.pageInfo.hasNextPage) {
                fetchMore({
                    variables: {
                        first,
                        after: alerts.userNotifications.pageInfo.endCursor,
                    },
                });
            }
        });
        if (node) subcontractorObserver.current.observe(node);
    });

    // eslint-disable-next-line
    const lastBundleElement = useCallback((node) => {
        if (bundlesObserver.current) bundlesObserver.current.disconnect();
        bundlesObserver.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting && bundles.buildersWithoutBundles.pageInfo.hasNextPage) {
                fetchMoreBundles({
                    variables: {
                        first,
                        after: bundles.buildersWithoutBundles.pageInfo.endCursor,
                    },
                });
            }
        });
        if (node) bundlesObserver.current.observe(node);
    });

    const [getAlerts, { data: alerts, fetchMore, loading: alertLoading }] = useLazyQuery(FETCH_ALERTS, {
        variables: {
            first: first,
        },
        fetchPolicy: "network-only",
        onCompleted: (result) => {},
    });

    const [
        getBuildersWithNoBundles,
        { data: bundles, fetchMore: fetchMoreBundles, loading: bundlesLoading },
    ] = useLazyQuery(BUILDERS_WITHOUT_BUNDLES, {
        variables: {
            first: first,
        },
        onCompleted: (result) => {},
    });

    const [getAdPosts, { data: adData, loading: adLoading }] = useLazyQuery(FETCH_TM_AD_POST, {
        notifyOnNetworkStatusChange: false,
        client: wordPressClient,
    });

    const [getDeadline, { data: deadline }] = useLazyQuery(FETCH_DEADLINE, {
        notifyOnNetworkStatusChange: false,
        client: wordPressClient,
    });

    const setCurrentYearandQuarter = () => {
        var today = new Date();
        var quarter = Math.floor((today.getMonth() + 3) / 3);
        var year = today.getFullYear();
        setCurrentYearQuarter({
            year: year,
            quarter: quarter,
        });
    };

    const alertHandler = (type, entity) => {
        if (type === "Programs" && entity?.id) {
            history.push({
                pathname: "/programs",
                state: { id: entity?.id, from: "notification" },
            });
        } else if (type === "Organizations" && entity?.id) {
            history.push({
                pathname: "/profiles/builders",
                state: { id: entity?.id, from: "notification" },
            });
        }
        // } else if (type === "Bundles" && entity?.id) {
        //     history.push({
        //         pathname: "/reporting/bundles",
        //         state: {
        //             id: entity?.id,
        //             org_id: entity?.organization?.id,
        //             from: "notification",
        //         },
        //     });
        // } else if (type === "SubDivision" && entity?.id) {
        //     history.push({
        //         pathname: "/reporting/addresses",
        //         state: {
        //             id: entity?.id,
        //             org_id: entity?.organization?.id,
        //             from: "notification",
        //         },
        //     });
        // }
    };

    const [getAnalyticsData, { data: analyticsData, loading: chartLoading }] = useLazyQuery(CLAIM_CHART_REPORT, {
        variables: {
            year: currentYearQuarter?.year,
            quarter: currentYearQuarter?.quarter,
            territoryManagerIds: [organizationNode?.id],
        },
    });

    const [getCurrentYearConversionRevenue, { data: currentYearConversion }] = useLazyQuery(GET_CONVERSION_REVENUE, {
        variables: {
            year: currentYearQuarter?.year,
        },
        notifyOnNetworkStatusChange: false,
        fetchPolicy: "network-only",
    });

    const [getPreviousYearConversionRevenue, { data: previousYearConversion }] = useLazyQuery(GET_CONVERSION_REVENUE, {
        variables: {
            year: currentYearQuarter?.year - 1,
        },
        notifyOnNetworkStatusChange: false,
        fetchPolicy: "network-only",
    });

    const makeGraphs = () => {
        let report = analyticsData?.claimChartReport;

        let yearCloseMax = Math.max(
            report?.yearCloseClaims?.factoryTotal + report?.yearCloseClaims?.volumeTotal,
            report?.yearCloseClaims?.previousResults?.factoryTotal +
                report?.yearCloseClaims?.previousResults?.volumeTotal
        );

        let periodCloseMax = Math.max(
            report?.periodCloseClaims?.factoryTotal + report?.periodCloseClaims?.volumeTotal,
            report?.periodCloseClaims?.previousResults?.factoryTotal +
                report?.periodCloseClaims?.previousResults?.volumeTotal
        );

        let conversionMax = Math.max(
            currentYearConversion?.conversionRevenue?.yearTotal?.total,
            previousYearConversion?.conversionRevenue?.yearTotal?.total
        );

        let totalMax = Math.max(yearCloseMax, periodCloseMax, conversionMax);

        let maxHeight = 175;
        const findHeight = (value, relative) => {
            return (value * maxHeight) / relative;
        };

        let currentPeriodHeight = findHeight(
            report?.periodCloseClaims?.factoryTotal + report?.periodCloseClaims?.volumeTotal,
            totalMax
        );
        let pastPeriodHeight = findHeight(
            report?.periodCloseClaims?.previousResults?.factoryTotal +
                report?.periodCloseClaims?.previousResults?.volumeTotal,
            totalMax
        );

        let currentYearHeight = findHeight(
            report?.yearCloseClaims?.factoryTotal + report?.yearCloseClaims?.volumeTotal,
            totalMax
        );

        let pastYearHeight = findHeight(
            report?.yearCloseClaims?.previousResults?.factoryTotal +
                report?.yearCloseClaims?.previousResults?.volumeTotal,
            totalMax
        );

        let currentConversionHeight = findHeight(currentYearConversion?.conversionRevenue?.yearTotal?.total, totalMax);

        let pastConversionHeight = findHeight(previousYearConversion?.conversionRevenue?.yearTotal?.total, totalMax);

        return (
            <div className="flex w-full  space-x-5 bg-white rounded-lg">
                <div className="flex py-1  w-full px-2 space-x-2  4xl:space-x-10">
                    <div className="flex flex-col w-full justify-end items-center 4xl:pl-5">
                        <div className="flex   items-end w-full">
                            <div
                                className="w-full text-center flex flex-col   font-medium "
                                style={{ maxWidth: "100px" }}
                            >
                                <div className=" ">
                                    {formatterForCurrency.format(
                                        report?.periodCloseClaims?.previousResults?.factoryTotal +
                                            report?.periodCloseClaims?.previousResults?.volumeTotal
                                    )}
                                    <div
                                        className="bg-darkgray75 w-full duration-250 border-b-2 border-black transition-height"
                                        style={{
                                            height: `${isNaN(pastPeriodHeight) ? "0" : pastPeriodHeight}px`,
                                        }}
                                    >
                                        &nbsp;
                                    </div>
                                    <p className="pt-2">Q{currentYearQuarter?.quarter - 1}</p>
                                </div>
                            </div>
                            <div
                                className="w-full text-center flex flex-col   font-medium"
                                style={{ maxWidth: "100px" }}
                            >
                                <div className=" ">
                                    {formatterForCurrency.format(
                                        report?.periodCloseClaims?.factoryTotal + report?.periodCloseClaims?.volumeTotal
                                    )}
                                    <div
                                        className="bg-secondary w-full duration-250 border-b-2 border-black transition-height"
                                        style={{
                                            height: `${isNaN(currentPeriodHeight) ? "0" : currentPeriodHeight}px`,
                                        }}
                                    >
                                        &nbsp;
                                    </div>
                                    <p className="pt-2">Q{currentYearQuarter?.quarter}</p>
                                </div>
                            </div>
                        </div>
                        <p className=" text-secondary font-title">Reporting Period</p>
                    </div>
                    <div className="flex flex-col w-full justify-end items-center  4xl:pl-10">
                        <div className="flex     items-end w-full">
                            <div
                                className="w-full text-center flex flex-col   font-medium"
                                style={{ maxWidth: "100px" }}
                            >
                                <div className=" ">
                                    {formatterForCurrency.format(
                                        report?.yearCloseClaims?.previousResults?.factoryTotal +
                                            report?.yearCloseClaims?.previousResults?.volumeTotal
                                    )}
                                    <div
                                        className="bg-darkgray75 w-full duration-250 border-b-2 border-black transition-height"
                                        style={{
                                            height: `${isNaN(pastYearHeight) ? "0" : pastYearHeight}px`,
                                        }}
                                    >
                                        &nbsp;
                                    </div>
                                    <p className="pt-2">{currentYearQuarter?.year - 1}</p>
                                </div>
                            </div>
                            <div
                                className="w-full text-center flex flex-col   font-medium"
                                style={{ maxWidth: "100px" }}
                            >
                                <div className=" ">
                                    {formatterForCurrency.format(
                                        report?.yearCloseClaims?.factoryTotal + report?.yearCloseClaims?.volumeTotal
                                    )}
                                    <div
                                        className="bg-secondary w-full duration-250 border-b-2 border-black transition-height"
                                        style={{
                                            height: `${isNaN(currentYearHeight) ? "0" : currentYearHeight}px`,
                                        }}
                                    >
                                        &nbsp;
                                    </div>
                                    <p className="pt-2">{currentYearQuarter?.year}</p>
                                </div>
                            </div>
                        </div>
                        <p className=" text-secondary font-title">Total</p>
                    </div>
                    <div className="flex flex-col w-full justify-end items-center  4xl:pl-10">
                        <div className="flex     items-end w-full">
                            <div
                                className="w-full text-center flex flex-col   font-medium"
                                style={{ maxWidth: "100px" }}
                            >
                                <div className=" ">
                                    {formatterForCurrency.format(
                                        previousYearConversion?.conversionRevenue?.yearTotal?.total
                                    )}
                                    <div
                                        className="bg-darkgray75 w-full duration-250 border-b-2 border-black transition-height"
                                        style={{
                                            height: `${isNaN(pastConversionHeight) ? "0" : pastConversionHeight}px`,
                                        }}
                                    >
                                        &nbsp;
                                    </div>
                                    <p className="pt-2">{currentYearQuarter?.year - 1}</p>
                                </div>
                            </div>
                            <div
                                className="w-full text-center flex flex-col   font-medium"
                                style={{ maxWidth: "100px" }}
                            >
                                <div className=" ">
                                    {formatterForCurrency.format(
                                        currentYearConversion?.conversionRevenue?.yearTotal?.total
                                    )}
                                    <div
                                        className="bg-secondary w-full duration-250 border-b-2 border-black transition-height"
                                        style={{
                                            height: `${
                                                isNaN(currentConversionHeight) ? "0" : currentConversionHeight
                                            }px`,
                                        }}
                                    >
                                        &nbsp;
                                    </div>
                                    <p className="pt-2">{currentYearQuarter?.year}</p>
                                </div>
                            </div>
                        </div>
                        <p className=" text-secondary font-title">Conversion</p>
                    </div>
                </div>
                <div className="border-l   w-full flex flex-col" style={{ maxWidth: "350px" }}>
                    <div className="py-3  font-title text-secondary px-4 font-semibold border-b text-lg">
                        <p className=" text-left"> Projected Revenue Total</p>
                        { whoAmILoading?
                        <div className="flex items-end justify-end">
                        <Loader  width={25} height={25}/>
                        </div>
                        :
                        <p className="text-right">
                            {formatterForCurrency.format(organizationNode?.projectedRevenue?.projectedTotal)}
                        </p>
    }
                    </div>
                    <ul
                        className="overflow-auto scrollbar-thin scrollbar-thumb-lightPrimary scrollbar-track-gray-400"
                        style={{ minHeight: "14rem", maxHeight: "14rem" }}
                    >
                        {chartLoading ? (
                            <Loader />
                        ) : (
                            organizationNode?.projectedRevenue?.programs?.map((eachData, index) => {
                                return (
                                    <li
                                        className={`  border-b transition-all  border-l-4 border-l-primary  hover:border-l-6`}
                                        onClick={() =>
                                            history.push({
                                                pathname: "/programs",
                                                state: {
                                                    id: eachData?.id,
                                                    from: "notification",
                                                },
                                            })
                                        }
                                    >
                                        <div className="relative flex items-start py-3 justify-between px-4  ">
                                            <div className="flex flex-col">
                                                <div className="text-sm  font-semibold  ">
                                                    <div className="  focus:outline-none">
                                                        <span className="absolute inset-0" aria-hidden="true"></span>
                                                        {eachData.name}
                                                    </div>
                                                </div>
                                                <div className="flex items-center ">
                                                    <p className="text-sm text-gray-500 capitalize ">
                                                        {eachData?.type?.toLowerCase()}
                                                    </p>

                                                    <p className="text-sm text-gray-500 capitalize ml-1">
                                                        {eachData?.company?.name
                                                            ? "  - " + eachData?.company?.name
                                                            : ""}
                                                    </p>
                                                </div>
                                            </div>
                                            <p className="text-sm font-semibold">
                                                {" "}
                                                $
                                                {eachData?.contributedTotal
                                                    ? formatterForCurrency.format(eachData?.contributedTotal)
                                                    : "0"}{" "}
                                            </p>
                                        </div>
                                    </li>
                                );
                            })
                        )}
                    </ul>
                </div>
            </div>
        );
    };

    const displayDeadline = (d) => {
        var date = d?.split("/");
        var f = new Date(date?.[2], date?.[1] - 1, date?.[0]).getTime();

        let now = new Date().getTime();
        const compare = 1814400;

        if (f - compare > now) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <>
            {displayDeadline(deadline?.acfOptions?.deadlineAlert?.date) ? (
                <p className="py-3 font-title text-brickRed px-4 font-semibold border-b text-lg bg-white rounded-lg">
                    {deadline?.acfOptions?.deadlineAlert?.message} - {deadline?.acfOptions?.deadlineAlert?.date}
                </p>
            ) : null}
            <div className="   flex  w-full space-x-5">
                <div className="flex-1  flex  w-full">
                    <div className="h-full w-full ">
                        <div className="w-full h-full flex justify-center ">
                            {makeGraphs()}
                        </div>
                    </div>
                </div>
                <div className=" w-full bg-white border rounded-lg max-w-sm">
                    <ul
                        style={{ maxHeight: `calc(14rem + 79px)` }}
                        className=" flex-0 w-full h-full  overflow-auto border-l  border-white scrollbar-thin scrollbar-thumb-lightPrimary scrollbar-track-gray-400"
                    >
                        {bundlesLoading || whoAmILoading ? (
                            <Loader />
                        ) : (
                            organizationNode?.topBuilders?.map((eachData, index) => {
                                return (
                                    <li
                                        className={`  border-b transition-all  border-l-4 border-l-primary   hover:border-l-6 `}
                                        onClick={() =>
                                            history.push({
                                                pathname: "/profiles/builders",
                                                state: { id: eachData?.id, from: "notification" },
                                            })
                                        }
                                    >
                                        <div className="relative  ">
                                            <div className="text-sm py-3 px-2 font-semibold flex justify-between items-center text-darkgray75">
                                                <div className="  focus:outline-none">{eachData?.name}</div>
                                                <div className="  focus:outline-none">
                                                    {formatterForCurrency.format(
                                                        eachData?.projectedRevenue?.projectedTotal
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                );
                            })
                        )}
                    </ul>
                </div>
            </div>

            <div className="flex w-full space-x-5">
                <div className="h-full w-full bg-white border rounded-lg max-w-sm">
                    <p className="py-3 font-title text-secondary px-4 font-semibold border-b text-lg ">
                        Alert & Notifications
                    </p>
                    <ul
                        className={`flex-0 w-full h-full  overflow-auto border-l  border-white scrollbar-thin scrollbar-thumb-lightPrimary scrollbar-track-gray-400 ${
                            displayDeadline(deadline?.acfOptions?.deadlineAlert?.date) ? "max-h-44" : "max-h-60"
                        } `}
                    >
                        {alertLoading ? (
                            <Loader />
                        ) : (
                            alerts &&
                            alerts.userNotifications &&
                            alerts.userNotifications.edges.length !== 0 &&
                            alerts.userNotifications.edges.map((eachData, index) => {
                                let verify =
                                    eachData?.node?.related_entity?.id &&
                                    eachData?.node?.related_entity?.__typename !== "Disputes" &&
                                    eachData?.node?.related_entity?.__typename !== "Bundles" &&
                                    eachData?.node?.related_entity?.__typename !== "SubDivision";
                                if (index === alerts.userNotifications.edges.length - 1) {
                                    return (
                                        <li
                                            className={`  border-b transition-all  border-l-4    hover:border-l-6  border-l-primary`}
                                            ref={lastSubcontractorElement}
                                        >
                                            <div
                                                className={`${verify ? "cursor-pointer" : ""}`}
                                                onClick={() =>
                                                    alertHandler(
                                                        eachData?.node?.related_entity?.__typename,
                                                        eachData?.node?.related_entity
                                                    )
                                                }
                                            >
                                                <div className="text-sm py-3 px-4 font-semibold text-darkgray75 flex justify-between items-center">
                                                    <div className="  focus:outline-none">
                                                        {eachData.node.message}
                                                        {" - "}
                                                        {eachData?.node?.related_entity?.name
                                                            ? eachData?.node?.related_entity?.name
                                                            : null}
                                                    </div>
                                                    {verify ? (
                                                        <ArrowCircleRightIcon className="h-5 w-5 text-secondary" />
                                                    ) : null}
                                                </div>
                                            </div>
                                        </li>
                                    );
                                }
                                return (
                                    <li
                                        className={`  border-b transition-all  border-l-4    hover:border-l-6  border-l-primary `}
                                    >
                                        <div
                                            className={`${verify ? "cursor-pointer" : ""}`}
                                            onClick={() =>
                                                alertHandler(
                                                    eachData?.node?.related_entity?.__typename,
                                                    eachData?.node?.related_entity
                                                )
                                            }
                                        >
                                            <div className="text-sm py-3 px-4 font-semibold text-darkgray75 flex justify-between items-center">
                                                <div className="  focus:outline-none">
                                                    {eachData.node.message}
                                                    {" - "}
                                                    {eachData?.node?.related_entity?.name
                                                        ? eachData?.node?.related_entity?.name
                                                        : null}
                                                </div>
                                                {verify ? (
                                                    <ArrowCircleRightIcon className="h-5 w-5 text-secondary" />
                                                ) : null}
                                            </div>
                                        </div>
                                    </li>
                                );
                            })
                        )}
                    </ul>
                </div>

                <div className="bg-white border rounded-lg w-full">
                    <p className="py-3 font-title text-secondary px-4 font-semibold border-b text-lg">
                        Rebate Reporting
                    </p>
                    <ul className="flex flex-col px-4 py-2">
                        { whoAmILoading?
                        <Loader />
                        :
                        <li className="my-2 flex  rounded-md border">
                            <div
                                className={`flex-shrink-0 flex items-center justify-center w-16 text-brickRed text-xl font-medium rounded-l-md`}
                            >
                                {organizationNode?.managedOrganizationsActionRequiredRebatesCount?.rebateCount?.toLocaleString()}
                            </div>
                            <div className="flex-1 flex items-center justify-between bg-white border-l rounded-r-md truncate">
                                <div className="flex-1 px-4 py-2 text-lg">
                                    <Link
                                        to={{ pathname: "/reporting/prepare" }}
                                        className="text-gray-900 font-title  hover:text-gray-600"
                                    >
                                        Actions Required
                                    </Link>
                                </div>
                            </div>
                        </li>
}
{ whoAmILoading?
                        <Loader />
                        :
                        <li className="my-2 flex  rounded-md border">
                            <div
                                className={`flex-shrink-0 flex items-center justify-center w-16 text-secondary text-xl font-medium rounded-l-md`}
                            >
                                {organizationNode?.managedOrganizationsReadiedRebatesCount?.rebateCount?.toLocaleString()}
                            </div>
                            <div className="flex-1 flex items-center justify-between bg-white border-l rounded-r-md truncate">
                                <div className="flex-1 px-4 py-2 text-lg">
                                    <Link
                                        to={{ pathname: "/reporting/prepare", active: "1" }}
                                        className="text-gray-900 font-title  hover:text-gray-600"
                                    >
                                        Ready for Rebate
                                    </Link>
                                </div>
                            </div>
                        </li>
}
                    </ul>
                </div>

                <div className=" bg-white border rounded-lg w-full ">
                    <p className="py-3 font-title text-secondary px-4 font-semibold border-b text-lg ">
                        "No Bundles" List
                    </p>
                    <ul className={`flex-0 w-full h-full  overflow-auto border-l  border-white scrollbar-thin scrollbar-thumb-lightPrimary scrollbar-track-gray-400   ${displayDeadline(deadline?.acfOptions?.deadlineAlert?.date) ? "max-h-44" : "max-h-60"}`}>
                        {bundlesLoading ? (
                            <Loader />
                        ) : (
                            bundles &&
                            bundles.buildersWithoutBundles &&
                            bundles.buildersWithoutBundles.edges.length !== 0 &&
                            bundles.buildersWithoutBundles.edges.map((eachData, index) => {
                                if (index === bundles.buildersWithoutBundles.edges.length - 1) {
                                    return (
                                        <li
                                            className={`  border-b transition-all  border-l-4 border-l-primary   hover:border-l-6 `}
                                            ref={lastBundleElement}
                                        >
                                            <div className="relative  ">
                                                <div className="text-sm py-3 px-2 font-semibold text-darkgray75">
                                                    <Link to="#" className="  focus:outline-none">
                                                        {eachData.node.name}
                                                    </Link>
                                                </div>
                                            </div>
                                        </li>
                                    );
                                }
                                return (
                                    <li
                                        className={`  border-b transition-all  border-l-4 border-l-primary   hover:border-l-6  `}
                                    >
                                        <div className="relative  ">
                                            <div className="text-sm py-3 px-2 font-semibold  text-darkgray75">
                                                <Link to="#" className="  focus:outline-none">
                                                    {eachData.node.name}
                                                </Link>
                                            </div>
                                        </div>
                                    </li>
                                );
                            })
                        )}
                    </ul>
                </div>
            </div>

            <div className="   grid grid-cols-3  w-full gap-5">
                <div className="w-full border bg-white rounded-lg col-span-2 relative ">
                    <Testimonial slides={adData} loading={adLoading} />
                </div>

                <div className=" w-full bg-white border rounded-lg ">
                    <p className="py-3 font-title text-secondary px-4 font-semibold border-b text-lg ">My Profile</p>
                    <div className="flex  space-x-5 p-4">
                        <div
                            className="block bg-center bg-cover"
                            style={{
                                backgroundImage: `url(${organizationNode?.userImage})`,
                                width: "130px",
                                height: "150px",
                            }}
                        ></div>
                        <div className="mt-2">
                            <p className="font-title text-secondary text-lg">
                                {organizationNode?.first_name} {organizationNode?.last_name}
                            </p>
                            <p className="font-title text-secondary text-lg">{organizationNode?.email} </p>
                            <p className="font-title text-secondary text-lg">{organizationNode?.mobile_phone} </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TMDashboard;
